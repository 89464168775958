import { combineReducers } from 'redux';
import viewport from './viewport';
import mapStyle from './mapStyle';
import markers from './markers';

export default combineReducers({
  viewport,
  mapStyle,
  markers,
});
