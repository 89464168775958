import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DashboardHome from '../components/DashboardHome';

const Dashboard = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route exact path={`${match.url}/`} component={DashboardHome} />
    </Switch>
  </React.Fragment>
);

export default Dashboard;
