import React from 'react';
import { Container, Grid, Segment, Header, Placeholder } from 'semantic-ui-react';

export default () => (
  <Container>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Segment fluid>
            <Header content="Getting things situated..." />
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
