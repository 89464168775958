import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import { forEach } from 'lodash-es';

export const HEADERS = {
  ACCESS_TOKEN: 'Authorization',
  ID_TOKEN: 'X-CB-ID',
  REFRESH_TOKEN: 'X-CB-REFRESH',
};

export const cookies = new Cookies();
export const getCookies = (name, options) => {
  if (name) {
    return cookies.get(name, options);
  }
  return cookies.getAll(options);
};

export const getTokenMetadata = ({ name, token }) => {
  if (token) {
    return jwt.decode(token);
  }
  const idToken = getCookies(name);
  return jwt.decode(idToken);
};

export const goToLogin = () =>
  window.location.replace(
    `https://${process.env.NODE_ENV === 'development' ? 'dev-' : ''}corkbord.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}&redirect_uri=${window.location.origin}`,
  );

export const setTokenCookies = ({ expires: expiresTime, ...tokens }) => {
  const expires = new Date();
  expires.setSeconds(expires.getSeconds() + parseInt(expiresTime, 10));
  const setCookie = (name, item) => cookies.set(name, item, { expires });
  forEach(tokens, (token, name) => token && setCookie(name, token, { expires }));
};
