import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import map from '../modules/map/reducers';
import toaster from './reducers/toaster';
import user from './reducers/user';
import loader from './reducers/loader';
import business from './reducers/business';
import businesses from './reducers/businesses';
import newAccountWizard from './reducers/new-account-wizard';
import offering from './reducers/offering';

export default combineReducers({
  // router: routerReducer,
  account: combineReducers({
    newAccountWizard,
    business,
    businesses,
  }),
  user,
  offering,
  map,
  toaster,
  loader,
});
