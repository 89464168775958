import { memoize } from 'lodash-es';

export const CHANGE_AUTO_COMPLETE_BUSINESS_VALUE =
  'new-account-wizard/CHANGE_AUTO_COMPLETE_BUSINESS_VALUE';
export const changeAutoCompleteBusinessValue = payload => ({
  type: CHANGE_AUTO_COMPLETE_BUSINESS_VALUE,
  payload,
});

const mapToAutoComplete = memoize(
  ({ field: description, value: title, oid }) => ({
    title,
    description,
    key: title + oid,
    oid,
  }),
  ({ title, oid }) => title + oid,
);
export const GET_AUTO_COMPLETE_BUSINESSES = 'new-account-wizard/GET_AUTO_COMPLETE_BUSINESSES';
export const searchForBusiness = text => async (dispatch, getState, { api }) => {
  dispatch(changeAutoCompleteBusinessValue(text));
  if (text) {
    const { data } = await api.get('/autocomplete/businesses', {
      params: {
        text,
        size: 20,
        fields: ['name', 'address', 'twitter', 'facebook'],
        hasOwner: false,
      },
    });
    dispatch({
      type: GET_AUTO_COMPLETE_BUSINESSES,
      payload: (data.docs || []).map(mapToAutoComplete),
    });
  }
};

export const SET_NEW_ACCOUNT_SELECTED_BUSINESS =
  'new-account-wizard/SET_NEW_ACCOUNT_SELECTED_BUSINESS';
export const setNewAccountSelectedBusiness = payload => ({
  type: SET_NEW_ACCOUNT_SELECTED_BUSINESS,
  payload,
});

export const CLEAR_NEW_ACCOUNT_SELECTED_BUSINESS =
  'new-account-wizard/CLEAR_NEW_ACCOUNT_SELECTED_BUSINESS';
export const clearNewAccountSelectedBusiness = payload => ({
  type: CLEAR_NEW_ACCOUNT_SELECTED_BUSINESS,
  payload,
});

export const GET_BUSINESS_BY_ID = 'new-account-wizard/GET_BUSINESS_BY_ID';
export const selectBusinessForNewAccount =
  id => async (dispatch, getState, { api, changeToast, toggleLoader }) => {
    dispatch(toggleLoader(true));
    const { data } = await api.get(`/businesses/${id}`);
    dispatch(toggleLoader(false));

    if (data && data.docs && data.docs.length) {
      dispatch(setNewAccountSelectedBusiness(data.docs[0]));
      dispatch({ type: GET_BUSINESS_BY_ID, payload: data.docs[0] });
    } else {
      dispatch(changeToast('Business was not found', { type: 'warning' }));
    }
  }

export const TOGGLE_USER_EDITING_NEW_BUSINESS =
  'new-account-wizard/TOGGLE_USER_EDITING_NEW_BUSINESS';
export const toggleUserEditingNewBusiness = payload => ({
  type: TOGGLE_USER_EDITING_NEW_BUSINESS,
  payload,
});

const initialState = { userEditing: false };
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_AUTO_COMPLETE_BUSINESS_VALUE:
      return {
        ...state,
        searchValue: payload,
      };
    case GET_AUTO_COMPLETE_BUSINESSES:
      return {
        ...state,
        results: payload,
      };
    case SET_NEW_ACCOUNT_SELECTED_BUSINESS:
      return {
        ...state,
        selectedBusiness: payload,
      };
    case CLEAR_NEW_ACCOUNT_SELECTED_BUSINESS:
      return {
        ...state,
        selectedBusiness: null,
      };
    case TOGGLE_USER_EDITING_NEW_BUSINESS:
      return {
        ...state,
        userEditing: payload,
      };
    default:
      return state;
  }
};
