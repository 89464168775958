import { unionWith, unionBy, isEqual, filter, omit } from 'lodash-es';
import moment from 'moment/moment';
import { roundedTime } from '../../utils';
import { toggleLoader } from './loader';
import { setUpdatedBusinessToOwnersList } from './businesses';
import { setBusiness } from './business';

export const STORE_OFFERING_PROP = 'STORE_OFFERING_PROP';
export const storeOfferingProperty = payload => ({ type: STORE_OFFERING_PROP, payload });

export const REMOVE_OFFERING_PROPERTIES = 'REMOVE_OFFERING_PROPERTIES';
export const removeOfferingProperties = payload => ({
  type: REMOVE_OFFERING_PROPERTIES,
  payload,
});

export const ADD_ITEM_TO_OFFERING = 'ADD_ITEM_TO_OFFERING';
export const addItemToOffering = payload => ({ type: ADD_ITEM_TO_OFFERING, payload });

export const REMOVE_ITEM_FROM_OFFERING = 'REMOVE_ITEM_FROM_OFFERING';
export const removeItemFromOffering = payload => ({
  type: REMOVE_ITEM_FROM_OFFERING,
  payload,
});

export const STORE_OFFERING = 'STORE_OFFERING';
export const sendOfferingToStore = payload => ({ type: STORE_OFFERING, payload });

export const CLEAR_OFFERING = 'CLEAR_OFFERING';
export const clearOffering = () => ({ type: CLEAR_OFFERING });

export const SET_OFFERING_IN_BUSINESS = 'SET_OFFERING_IN_BUSINESS';
export const setOfferingInBusiness = offering => async (
  dispatch,
  getState,
  { api, changeToast, history },
) => {
  dispatch(toggleLoader(true));

  const { business } = getState().account || {};
  const updatedBusiness = {
    ...business,
    offerings: unionBy([offering], business.offerings, 'id'),
  };
  const { data } = await api.post(`/businesses/${business.oid}`, { doc: updatedBusiness });

  dispatch(clearOffering());
  dispatch(setUpdatedBusinessToOwnersList(data.doc));
  dispatch(setBusiness(data.doc));
  dispatch(toggleLoader(false));
  dispatch(changeToast(`Your business was ${data.op}`, { type: 'success' }));
  history.push('/');
  dispatch({ type: SET_OFFERING_IN_BUSINESS, payload: updatedBusiness });
};

export const REMOVE_OFFERING_FROM_BUSINESS = 'REMOVE_OFFERING_FROM_BUSINESS';
export const removeOfferingFromBusiness = id => async (
  dispatch,
  getState,
  { api, changeToast },
) => {
  dispatch(toggleLoader(true));

  const { business } = getState().account || {};
  const updatedBusiness = {
    ...business,
    offerings: business.offerings.filter(({ id: offerId }) => offerId !== id),
  };

  const { data } = await api.post(`/businesses/${business.oid}`, { doc: updatedBusiness });
  dispatch(setUpdatedBusinessToOwnersList(data.doc));
  dispatch(setBusiness(data.doc));
  dispatch(toggleLoader(false));
  dispatch(changeToast(`Your business was ${data.op}`, { type: 'success' }));
  dispatch({ type: REMOVE_OFFERING_FROM_BUSINESS, payload: updatedBusiness });
};

const initialState = {
  id: moment().valueOf(),
  date: moment().format('YYYY-MM-DD'),
  timeStart: roundedTime(),
  timeEnd: roundedTime(moment().add(2, 'hours')),
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_OFFERING_PROP:
      return {
        ...state,
        ...payload,
      };
    case ADD_ITEM_TO_OFFERING:
      return {
        ...state,
        items: unionWith([payload], state.items || [], isEqual),
      };
    case CLEAR_OFFERING:
      return { ...initialState };
    case STORE_OFFERING:
      return { ...payload };
    case REMOVE_OFFERING_PROPERTIES:
      return { ...omit(state, payload) };
    case REMOVE_ITEM_FROM_OFFERING:
      return {
        ...state,
        // eslint-disable-next-line max-len
        items: filter(
          state.items,
          ({ item, price }) => item !== payload.item && price !== payload.price,
        ),
      };
    default:
      return state;
  }
};
