import moment from 'moment';
import { memoize } from 'lodash-es';
import { TIME_FORMAT } from '../enums';

export const roundedTime = time => {
  const start = time || moment();
  const remainder = 30 - (start.minute() % 30);
  return moment(start)
    .add(remainder, 'minutes')
    .format(TIME_FORMAT);
};

export const milTimeToStandard = memoize(time => moment(time, 'H:mm').format(TIME_FORMAT));

export const scrollToId = id => {
  const element = document.querySelector(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const createAddressString = memoize(
  ({ address, city, state, zipCode }) =>
    address && city && state && zipCode && `${address} ${city}, ${state} ${zipCode}`,
);
