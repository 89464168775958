import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit } from 'lodash-es';
import { Form } from 'formsy-semantic-ui-react';
import { Label, Divider } from 'semantic-ui-react';
import { CATEGORIES /* urlRegExp */ } from './enums';
import { DEFAULT_INPUT_PROPS } from '../../../../enums';
import { storeOfferingProperty } from '../../../../redux/reducers/offering';

const toProps = ({ offering }) => ({
  offering,
});

class OfferingFormBeginning extends PureComponent {
  static propTypes = {
    offering: PropTypes.object.isRequired,
    storeOfferingProperty: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  };

  state = { isValid: false };

  forms = {
    input: Form.Input,
    text: Form.TextArea,
    dropdown: Form.Dropdown,
  };

  formItems = [
    {
      formType: 'input',
      name: 'title',
      icon: 'newspaper',
      label: 'Event/Offering Title',
      placeholder: 'something like.. Awesome happy hour.. Special Charity Event.. Tasty Brunch!',
      validations: {
        minLength: 2,
      },
      validationErrors: {
        minLength: 'Please provide a title',
        isDefaultRequiredValue: 'Please provide a title',
      },
      instantValidation: false,
    },
    {
      formType: 'dropdown',
      name: 'categories',
      label: 'Event/Offering Category',
      icon: null,
      // search: true,
      fluid: true,
      scrolling: true,
      validations: {
        isExisty: true,
      },
      validationErrors: {
        isExisty: 'Please provide a category, ot two, or three.',
        isDefaultRequiredValue: 'Please provide a category, ot two, or three.',
      },
      instantValidation: false,
      multiple: true,
      onChange: (e, { name, value }) => this.props.storeOfferingProperty({ [name]: value }),
      options: CATEGORIES,
    },
    {
      formType: 'input',
      icon: 'feed',
      name: 'url',
      // value: 'https://',
      label: 'Event/Offering URL',
      placeholder: 'www...',
      required: false,
      // validations: {
      //   // matchRegexp: urlRegExp,
      //   // isExisty: true,
      // },
      // validationErrors: {
      //   // matchRegexp: 'Please provide an accurate URL',
      //   isExisty: 'Please provide a URL so people can find more info',
      //   isDefaultRequiredValue: 'Please provide a URL so people can find more info',
      // },
      // instantValidation: false,
    },
  ];

  render() {
    const { storeOfferingProperty, children, offering } = this.props;
    const errorLabel = <Label color="red" pointing basic />;
    return (
      <Form
        onValid={() => this.setState({ isValid: true })}
        onInvalid={() => this.setState({ isValid: false })}
      >
        {this.formItems.map(itm => {
          const Field = this.forms[itm.formType];
          const defaults =
            itm.formType === 'dropdown'
              ? omit(DEFAULT_INPUT_PROPS, 'transparent', 'iconPosition')
              : DEFAULT_INPUT_PROPS;
          const props = omit(itm, 'formType');
          return [
            <Field
              {...defaults}
              {...props}
              key={itm.name}
              value={offering[itm.name] || itm.value}
              onBlur={({ target }) => storeOfferingProperty({ [target.name]: target.value })}
              errorLabel={errorLabel}
            />,
            <Divider style={{ margin: '2px 0px 5px' }} key="div" />,
          ];
        })}
        {children({ ...this.state })}
      </Form>
    );
  }
}

export default connect(
  toProps,
  {
    storeOfferingProperty,
  },
)(OfferingFormBeginning);
