
export const TOGGLE_LOADER = 'loader/TOGGLE_LOADER';
export const toggleLoader = (isActive, message) => ({
  type: TOGGLE_LOADER,
  payload: { isActive, message },
});

const initialState = {
  isActive: false,
  message: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
