import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import DashboardOfferingList from '../containers/DashboardOfferingList';
import BusinessCardContainer from '../containers/BusinessCardContainer';
// import Map from '../../map/containers/Map';
// <Map height={300} width={500} />

export default props => (
  <Container>
    <Grid columns={2} divided="vertically" padded doubling>
      <Grid.Row>
        <Grid.Column width={6}>
          <BusinessCardContainer className="sticky" {...props} />
        </Grid.Column>
        <Grid.Column width={10}>
          <DashboardOfferingList {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
