import thunk from 'redux-thunk';
import reduxCatch from 'redux-catch';
import createHistory from 'history/createBrowserHistory';
import corkbordApi from '../enums/services';
import { handleErrorThunk, handleError, changeToast } from './actions/global';
import { toggleLoader } from './reducers/loader';

export const history = createHistory();

export const errorMiddleware = reduxCatch((error, getState, lastAction, dispatch) => {
  console.error(error);
  console.debug('current state', getState());
  console.debug('last action was', lastAction);
  dispatch(handleError(error));
  // optionally dispatch an action due to the error using the dispatch parameter
});

export const thunkMiddleware = thunk.withExtraArgument({
  handleError: handleErrorThunk,
  api: corkbordApi,
  changeToast,
  toggleLoader,
  history,
});
