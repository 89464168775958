import { flow, values, flatten, reduce, startCase, memoize } from 'lodash-es';
import { pick } from 'lodash/fp';

const mapToOptions = transformer =>
  memoize(o => ({ key: o, text: transformer ? transformer(o) : o, value: o }));
const startCaser = mapToOptions(startCase);

export const CATEGORIES = [
  'community',
  'drink',
  'educational',
  'family',
  'free',
  'food',
  'outdoors',
  'personal',
  'pet',
  'philanthropy',
  'stage',
  'fitness',
].sort().map(startCaser);

export const typeOptionsByCategory = reduce(
  {
    free: ['free', 'swag', 'drinks', 'food', 'experience'],
    drink: ['drink', 'happy_hour', 'shot_and_a_beer'],
    food: ['food'],
    philanthropy: ['philanthropy'],
    stage: ['live_music', 'poetry', 'acting', 'movie', 'stage'],
    pet: ['pet'],
    family: ['family', 'kids', 'x_and_under', 'games'],
    community: ['community'],
    outdoors: ['outdoors', 'adventure', 'chill'],
    educational: ['educational', 'certification', 'public_speaker'],
    personal: ['personal', 'birthday'],
    fitness: ['workout', 'stretching', 'education'],
  },
  (agg, types, k) => ({ ...agg, [k]: types.map(startCaser) }),
  {},
);

export const getTypesList = memoize(types =>
  flow(
    pick(types),
    values,
    flatten,
  )(typeOptionsByCategory),
);

// eslint-disable-next-line
export const urlRegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
