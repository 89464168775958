import { CHANGE_TOAST, CLEAR_TOAST } from '../actions/global';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TOAST:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_TOAST:
      return { ...initialState };
    default:
      return state;
  }
};
