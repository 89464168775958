import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { startCase, upperCase } from 'lodash-es';
import { Card, Label } from 'semantic-ui-react';
import OfferingItemsList from './OfferingItemsList';

export default class OfferingSummary extends PureComponent {
  static propTypes = {
    offering: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  };

  formatDate = date => {
    if (date) {
      return new Date(date).toLocaleString().split(' ')[0];
    }
    return null;
  };

  render() {
    const { offering, children, style, onClick } = this.props;
    const {
      title,
      categories,
      url,
      date,
      recur,
      recmult,
      dow,
      timeStart,
      timeEnd,
      items,
      description,
    } = offering;
    // todo: link click as portal / iframe

    return (
      <Card raised fluid style={style}>
        <Card.Content>
          <Card.Header
            as="a"
            content={startCase(title) || 'Offering'}
            style={{ paddingTop: 5, cursor: 'pointer' }}
            onClick={onClick}
          />
          <Card.Meta
            content={`${this.formatDate(date) ||
              `Every ${recmult} ${recur}${recmult > 1 ? 's' : ''}`} ${(dow &&
              `on ${dow.join(', ')}`) ||
              ''} ${timeStart} until ${timeEnd}`}
          />
          {url && <Card.Description content="Event Link" href={url} target="_blank" />}
        </Card.Content>
        {description && (
          <Card.Content extra>
            <p>{description}</p>
          </Card.Content>
        )}
        {items && items.length && (
          <Card.Content extra>
            <OfferingItemsList items={items} />
          </Card.Content>
        )}
        <Card.Content extra style={{ paddingBottom: children && 20 }}>
          {children}
          <Label
            style={{ marginTop: 16 }}
            ribbon="right"
            icon="tag"
            color="blue"
            content={(categories || []).map(upperCase).join(', ')}
          />
        </Card.Content>
      </Card>
    );
  }
}
