import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { sample } from 'lodash-es';
import { FUN_EVENT_FACTS } from '../../../enums';

const toState = ({ loader }) => ({
  ...loader,
});

const LoadingDimmer = ({ isActive, message, inverted = true }) => (
  <Dimmer active={isActive} inverted={inverted}>
    <Loader active={isActive} size="large" content={message || sample(FUN_EVENT_FACTS)} />
  </Dimmer>
);

export default connect(toState)(LoadingDimmer);
