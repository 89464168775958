import React from 'react';
import { connect } from 'react-redux';
import { Search } from 'semantic-ui-react';
import {
  searchForBusiness,
  selectBusinessForNewAccount,
} from '../../../redux/reducers/new-account-wizard';

const toProps = ({ account }) => ({
  ...account.newAccountWizard,
});

const BusinessSearch = ({
  results,
  searchValue,
  searchForBusiness,
  selectBusinessForNewAccount,
  inputProps,
  searchProps,
}) => {
  return (
    <Search
      input={{ ...inputProps }}
      icon={{ name: 'search' }}
      fluid
      placeholder="Search by name, twitter, facebook, or address"
      value={searchValue}
      results={results}
      minCharacters={3}
      noResultsMessage="Unable to Find Organization"
      noResultsDescription="It's possible we don't have that organization in our records or it may already have an owner. If you believe this is an error please let us know!"
      onSearchChange={(e, { value }) => searchForBusiness(value)}
      onResultSelect={(e, { result }) => selectBusinessForNewAccount(result.oid)}
      {...searchProps}
    />
  );
};

export default connect(
  toProps,
  {
    searchForBusiness,
    selectBusinessForNewAccount,
  },
)(BusinessSearch);
