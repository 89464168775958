import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';

export default ({ children, style }) => (
  <Container style={style}>
    <Grid stretched columns={1}>
      <Grid.Row stretched>
        <Grid.Column width={16}>
          <Segment style={{ backgroundColor: 'rgba(255, 255, 255, 0.89)' }}>{children}</Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
