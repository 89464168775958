import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'react-router-redux';
import { Container } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { history } from './redux/middleware';
import store from './redux/store';
import Toaster from './modules/core/containers/Toaster';
import Loader from './modules/core/containers/Loader';
import MenuBar from './modules/core/components/MenuBar';
import Dashboard from './modules/dashboard/containers/Dashboard';
import Account from './modules/account/components/Account';
import Waiting from './modules/core/components/WaitingSocketAuth';
import onLoad from './appInit';

export default () => {
  useEffect(() => {
    onLoad(store.dispatch, history);
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <Container fluid>
          <Toaster />
          <Loader />
          <MenuBar />
          <div style={{ paddingTop: 85 }} className="full-background">
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/account" component={Account} />
              <Route path="/waiting" component={Waiting} />
              <Route path="*" render={() => <div> No Match </div>} />
            </Switch>
          </div>
        </Container>
      </Router>
    </Provider>
  );
};
