import { unionBy } from 'lodash-es';
import { REMOVE_OWNER_FROM_BUSINESS, setBusiness } from './business';
import { toggleLoader } from './loader';

export const SET_OWNERS_BUSINESSES = 'business/SET_OWNERS_BUSINESSES';
export const setOwnersBusinesses = payload => ({
  type: SET_OWNERS_BUSINESSES,
  payload,
});

export const SET_UPDATED_BUSINESS_TO_OWNERS_LIST = 'businesses/SET_UPDATED_BUSINESS_TO_OWNERS_LIST';
export const setUpdatedBusinessToOwnersList = payload => ({
  type: SET_UPDATED_BUSINESS_TO_OWNERS_LIST,
  payload,
});

export const FETCH_OWNERS_BUSINESSES = 'business/FETCH_OWNERS_BUSINESSES';
export const fetchOwnersBusiness = () => async (
  dispatch,
  getState,
  { api, changeToast, history },
) => {
  const { data } = await api.get('/users/owners');
  if (data.docs && data.docs.length) {
    dispatch(setOwnersBusinesses(data.docs));
    dispatch(setBusiness(data.docs[0])); // select first one for convenience
    dispatch(
      changeToast(`Your business${data.docs.length > 1 ? 'es were' : ' was'} found`, {
        type: 'success',
      }),
    );
  } else {
    dispatch(toggleLoader(false));
    dispatch(changeToast('No businesses found for user', { type: 'info' }));
  }

  history.push('/');
  dispatch({ type: FETCH_OWNERS_BUSINESSES });
  // };
};

const initialState = [];
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OWNERS_BUSINESSES:
      return payload;
    case SET_UPDATED_BUSINESS_TO_OWNERS_LIST:
      return unionBy([payload], state, 'oid');
    case REMOVE_OWNER_FROM_BUSINESS:
      return state.filter(({ oid }) => oid !== payload);
    default:
      return state;
  }
};
