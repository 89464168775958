import { createStore, applyMiddleware, compose } from 'redux';
import persistState from 'redux-localstorage';
import { pick, memoize } from 'lodash-es';
import rootReducer from './root-reducer';
import { thunkMiddleware, errorMiddleware } from './middleware';

const configureStore = () => {
  // eslint-disable-next-line
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(errorMiddleware, thunkMiddleware),
      persistState(
        ['account.businesses', 'offering', 'map.viewport', 'user.email', 'user.username'],
        {
          key: 'corkbord-business',
          serialize: memoize(JSON.stringify),
          deserialize: memoize(JSON.parse),
          slicer: paths => state => pick(state, paths),
        },
      ),
    ),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./root-reducer', () => {
        const nextReducer = require('./root-reducer').default; // eslint-disable-line global-require
        store.replaceReducer(nextReducer);
      });
    }
  }
  return store;
};

const store = configureStore();

export default store;
