export const MAP_VIEWPORT_CHANGE = 'MAP_VIEWPORT_CHANGE';
export const ADD_SOURCE_TO_MAP = 'ADD_SOURCE_TO_MAP';
export const ADD_LAYER_TO_MAP = 'ADD_LAYER_STYLE_TO_MAP';
export const ADD_MARKER_GROUP = 'ADD_MARKER_GROUP';
export const REMOVE_MARKER_GROUP = 'REMOVE_MARKER_GROUP';
export const REMOVE_SOURCE_BY_ID = 'REMOVE_SOURCE_BY_ID';

export const onViewportChange = viewport => ({ type: MAP_VIEWPORT_CHANGE, payload: viewport });

// ref: https://www.mapbox.com/mapbox-gl-js/style-spec/#sources-geojson
export const addSourceToMap = (name, source) => ({
  type: ADD_SOURCE_TO_MAP,
  payload: { name, source },
});

export const addLayersToMap = layer => ({
  type: ADD_LAYER_TO_MAP,
  // eslint-disable-next-line no-nested-ternary
  payload: Array.isArray(layer) ? layer : typeof layer === 'object' ? [layer] : null,
});

// expects: {name: {geojson}}
export const addMarkerGroup = (name, markers) => {
  const { features } = markers;
  if (features) {
    return { type: ADD_MARKER_GROUP, payload: { name, markers: features } };
  }
  return { type: ADD_MARKER_GROUP, payload: { name, markers } };
};

export const removeMarkerGroup = name => ({ type: REMOVE_MARKER_GROUP, payload: name });

export const removeSource = payload => ({ type: REMOVE_SOURCE_BY_ID, payload });
