import { SET_OFFERING_IN_BUSINESS } from '../reducers/offering';
import { setUpdatedBusinessToOwnersList } from './businesses';
import { toggleLoader } from './loader';

export const STORE_CONTACT_FIELD = 'business/STORE_CONTACT_FIELD';
export const storeContactProperty = payload => ({ type: STORE_CONTACT_FIELD, payload });

export const SET_BUSINESS = 'business/SET_BUSINESS';
export const setBusiness = payload => ({ type: SET_BUSINESS, payload });

export const CLEAR_BUSINESS = 'business/CLEAR_BUSINESS';
export const clearBusiness = () => ({ type: CLEAR_BUSINESS });

export const REMOVE_OWNER_FROM_BUSINESS = 'business/REMOVE_OWNER_FROM_BUSINESS';
export const removeOwnerFromBusiness = id => async (dispatch, getState, { api, changeToast }) => {
  await api.delete(`/users/owners/${id}`); // todo: handle return
  dispatch(changeToast('Your business was removed', { type: 'warning' }));
  return dispatch({ type: REMOVE_OWNER_FROM_BUSINESS, payload: id });
};

export const POST_BUSINESS_INFO = 'business/POST_BUSINESS_INFO';
export const postBusinessInfo = business => async (dispatch, getState, { api, changeToast }) => {
  dispatch(toggleLoader(true));
  const { data } = await api.post(`/businesses${business.oid ? `/${business.oid}` : ''}`, {
    doc: business,
  });
  dispatch(setUpdatedBusinessToOwnersList(data.doc));
  dispatch(toggleLoader(false));
  dispatch(changeToast(`Your business was ${data.op}`, { type: 'success' }));
  return dispatch({ type: POST_BUSINESS_INFO, payload: data.doc });
};

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_CONTACT_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    case SET_BUSINESS:
    case POST_BUSINESS_INFO:
    case SET_OFFERING_IN_BUSINESS:
      return {
        ...action.payload,
      };
    case CLEAR_BUSINESS:
      return initialState;
    default:
      return state;
  }
};
