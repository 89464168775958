import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from 'formsy-semantic-ui-react';
import { omit } from 'lodash-es';
import { Label, Transition, Divider, Checkbox, Message } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import TimeInput from '../../../core/components/TimeInput';
import {
  storeOfferingProperty,
  removeOfferingProperties,
} from '../../../../redux/reducers/offering';
import {
  DEFAULT_INPUT_PROPS,
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_OPTIONS,
  RECUR_OPTIONS,
} from '../../../../enums';

const toProps = ({ offering }) => ({
  offering,
});

/* eslint-disable  max-len */
class NewEstablishment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      isRecur: !!props.offering.recur,
      checkIfLateNight: false,
      isLateNight: false,
    };
  }

  componentWillMount() {
    const { offering } = this.props;
    const { timeStart, timeEnd } = offering;
    if (timeStart && timeEnd) {
      this.checkTimes(timeStart, timeEnd);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { offering } = nextProps;
    const { timeStart, timeEnd } = offering;
    if (timeStart && timeEnd) {
      this.checkTimes(timeStart, timeEnd);
    }
  }

  handleSetIsRecurring = toggle => {
    const { offering, removeOfferingProperties } = this.props;
    this.setState({ isRecur: toggle, isValid: true });
    if (offering.recmult || offering.recur || offering.dow) {
      // this.form.reset({ recmult: 1, recur: undefined, dow: undefined });
      removeOfferingProperties(['recur', 'recmult', 'dow']);
    }
    if (offering.date) {
      // this.form.reset({ date: undefined });
      removeOfferingProperties(['date']);
    }
  };

  handleRecurChange = (e, { name, value }) => {
    if (value === 'day' && this.props.offering.recmult === 1) {
      this.props.storeOfferingProperty({ [name]: value, dow: DAYS_OF_WEEK });
    } else {
      this.props.storeOfferingProperty({ [name]: value });
    }
  };

  checkTimes = (start, end) => {
    const s = moment(start, ['LT', 'h:mm']);
    const e = moment(end, ['LT', 'h:mm']);
    if (e < s && !this.state.checkIfLateNight) {
      this.setState({ checkIfLateNight: true });
    } else if (e > s && this.state.checkIfLateNight) {
      this.setState({ checkIfLateNight: false });
    }
  };

  handleStoreDate = d => {
    const date = moment(d).format('YYYY-MM-DD');
    this.props.storeOfferingProperty({ date });
  };

  render() {
    const { storeOfferingProperty, children, offering } = this.props;
    const { date, recur, recmult, dow, timeStart, timeEnd } = offering;
    const { isRecur, isValid, checkIfLateNight, isLateNight } = this.state;
    const errorLabel = <Label color="red" pointing basic />;
    /* eslint-disable react/no-unescaped-entities */
    return (
      <Form
        ref={node => (this.form = node)} // eslint-disable-line no-return-assign
        onValid={() => this.setState({ isValid: true })}
        onInvalid={() => this.setState({ isValid: false })}
      >
        <Form.Group widths="equal">
          <Form.Checkbox
            name="recur"
            toggle
            defaultChecked={!!recur}
            label={`Switch to: ${isRecur ? 'One Day Only' : 'Recurring Event'}`}
            onChange={(e, { checked }) => this.handleSetIsRecurring(checked)}
          />
          {isRecur ? (
            <React.Fragment>
              <Form.Input
                {...DEFAULT_INPUT_PROPS}
                name="recmult"
                label="Repeat Every.."
                type="number"
                className="input-light-font small-margin"
                min={1}
                fluid
                required={isRecur}
                value={recmult}
                errorLabel={errorLabel}
                validations={{ isExisty: true }}
                onChange={(e, { name, value }) =>
                  storeOfferingProperty({ [name]: parseInt(value, 10) })
                }
                validationErrors={{ isExisty: 'Please provide a recurrence schedule' }}
              />
              <Form.Dropdown
                {...omit(DEFAULT_INPUT_PROPS, 'transparent', 'iconPosition')}
                name="recur"
                label="..Recurring.."
                fluid
                scrolling
                required={isRecur}
                value={recur}
                errorLabel={errorLabel}
                validations={{ isExisty: true }}
                onChange={this.handleRecurChange}
                validationErrors={{ isExisty: 'Please provide a recurrence schedule' }}
                options={RECUR_OPTIONS}
              />
              <Form.Dropdown
                {...omit(DEFAULT_INPUT_PROPS, 'transparent', 'iconPosition')}
                name="dow"
                label="..On Days"
                required={isRecur}
                multiple
                fluid
                scrolling
                value={dow}
                errorLabel={errorLabel}
                onChange={(e, { name, value }) => storeOfferingProperty({ [name]: value })}
                validations={{ isExisty: true }}
                validationErrors={{ isExisty: 'Please provide days of the week' }}
                options={DAYS_OF_WEEK_OPTIONS}
              />
            </React.Fragment>
          ) : (
            <Form.Field
              name="date"
              label="On Day"
              style={{ backgroundColor: 'transparent' }}
              format="YYYY-MM-DD"
              placeholder="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
              value={date}
              required={!isRecur}
              validations={{ isExisty: true }}
              validationErrors={{ isExisty: 'Please provide a date' }}
              errorLabel={errorLabel}
              classNames={{ container: 'dp-container', overlay: 'dp-overlay' }}
              onDayChange={this.handleStoreDate}
              control={DayPickerInput}
            />
          )}
        </Form.Group>
        <Divider horizontal>TIMES</Divider>
        <Form.Group widths="equal" inline>
          <TimeInput
            name="timeStart"
            label="Start Time"
            icon="clock"
            fluid
            value={timeStart}
            onChange={timeStart => storeOfferingProperty({ timeStart })}
          />
          <TimeInput
            name="timeEnd"
            label="End Time"
            icon="clock"
            fluid
            value={timeEnd}
            onChange={timeEnd => storeOfferingProperty({ timeEnd })}
          />
        </Form.Group>
        {timeStart === timeEnd && (
          <Label
            color="red"
            basic
            pointing
            content="The start time cannot equal end time"
            style={{ marginTop: 0, marginBottom: 5 }}
          />
        )}
        <Transition visible={checkIfLateNight} animation="slide down">
          <Message>
            <Message.Header style={{ paddingRight: 11 }}>
              Your end time ({timeEnd}) is "before" your start({timeStart}), is this because its a
              late night event?
            </Message.Header>
            <Message.Content>
              <Checkbox
                name="confirm-late"
                checked={isLateNight}
                toggle
                fitted
                onChange={(e, { checked }) => this.setState({ isLateNight: checked })}
              />
              <span style={{ paddingLeft: 11 }}>
                {isLateNight ? 'Yes, its late night' : 'Nope! Lemmie fix it..'}
              </span>
            </Message.Content>
          </Message>
        </Transition>
        <Divider horizontal inverted fitted />
        {children({
          isValid:
            isValid &&
            (date || (recmult && (dow || []).length)) &&
            timeStart &&
            timeEnd &&
            timeStart !== timeEnd &&
            (checkIfLateNight ? isLateNight : true),
        })}
      </Form>
    );
  }
}

export default connect(
  toProps,
  {
    storeOfferingProperty,
    removeOfferingProperties,
  },
)(NewEstablishment);
