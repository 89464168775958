import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { OfferingFormBeginning, OfferingFormWhen, OfferingFormItems } from './index';
import OfferingSummary from '../OfferingSummary';
import { clearOffering } from '../../../../redux/reducers/offering';

const toProps = () => ({});

class OfferingsWizard extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    confirmClear: false,
  };

  routes = [
    { path: `${this.props.match.url}/who`, Component: OfferingFormBeginning },
    { path: `${this.props.match.url}/when`, Component: OfferingFormWhen },
    { path: `${this.props.match.url}/what`, Component: OfferingFormItems },
    { path: `${this.props.match.url}/summary`, Component: OfferingSummary },
  ];

  clearOffering = () => {
    this.props.clearOffering();
    this.props.history.push('/account/create');
    this.setState({ confirmClear: false });
  };

  renderRoute = ({ Component, idx }) => props => {
    const { history } = this.props;
    const prevRoute = (this.routes[idx - 1] || this.routes[0]).path;
    const nextRoute = (this.routes[idx + 1] || this.routes[0]).path;
    return (
      <Component {...props}>
        {({ isValid }) => (
          <Button.Group fluid>
            <Button
              color="orange"
              icon="arrow left"
              onClick={() => {
                if (idx === 0) {
                  history.goBack();
                } else {
                  history.push(prevRoute);
                }
              }}
            />
            <Button
              color={isValid ? 'blue' : 'red'}
              icon="arrow right"
              disabled={!isValid}
              onClick={() => history.push(nextRoute)}
            />
          </Button.Group>
        )}
      </Component>
    );
  };

  render() {
    const { confirmClear } = this.state;
    const { match } = this.props;
    return (
      <FormWrapper style={{ paddingTop: 65 }}>
        <span>
          {confirmClear ? (
            <div style={{ float: 'right', marginTop: 20 }}>
              <div>Start Over?</div>
              <Button.Group floated="right">
                <Button
                  color="red"
                  title="Don't clear"
                  content="Nope!"
                  onClick={() => this.setState({ confirmClear: false })}
                />
                <Button.Or />
                <Button color="orange" title="Clear" content="yep!" onClick={this.clearOffering} />
              </Button.Group>
            </div>
          ) : (
            <Button
              basic
              circular
              floated="right"
              style={{ float: 'right', marginTop: 20 }}
              title="Clear Form"
              color="red"
              onClick={() => this.setState({ confirmClear: true })}
              icon="remove"
            />
          )}
        </span>
        <Header
          as="h3"
          icon="idea"
          content="Create an Event/Offering"
          subheader="Share the details with us, we'll do the rest!"
        />
        <Switch>
          {this.routes.map(({ path, Component }, idx) => (
            <Route key={path} path={path} render={this.renderRoute({ Component, idx })} />
          ))}
          <Redirect to={`${match.url}/who`} />
        </Switch>
      </FormWrapper>
    );
  }
}

export default connect(
  toProps,
  {
    clearOffering,
  },
)(OfferingsWizard);
