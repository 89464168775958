import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash-es';
import { Form } from 'formsy-semantic-ui-react';
import { Label, Divider, Grid, Transition, Checkbox } from 'semantic-ui-react';
import { getTypesList } from './enums';
import { DEFAULT_INPUT_PROPS } from '../../../../enums';
import {
  storeOfferingProperty,
  addItemToOffering,
  removeItemFromOffering,
} from '../../../../redux/reducers/offering';
import OfferingItemsList from '../../components/OfferingItemsList';

const toProps = ({ offering }) => ({
  offering,
});

/* eslint-disable react/prop-types */
class OfferingFormItems extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      isShowingDescription: false,
      item: {},
    };
  }

  componentWillMount() {
    // eslint-disable-next-line max-len
    if (
      !this.state.isShowingDescription &&
      this.props.offering &&
      this.props.offering.description
    ) {
      this.setState({ isShowingDescription: true });
    }
  }

  forms = {
    input: Form.Input,
    text: Form.TextArea,
    dropdown: Form.Dropdown,
  };

  formItems = [
    {
      formType: 'dropdown',
      name: 'type',
      icon: null,
      label: 'Category',
      search: true,
      fluid: true,
      scrolling: true,
      validations: {
        isExisty: true,
      },
      validationErrors: {
        isExisty: 'Please provide a category for your item',
        isDefaultRequiredValue: 'Please provide a category for your item',
      },
      instantValidation: false,
      onChange: (e, { name, value }) => this.saveItemProperty({ [name]: value }),
      options: getTypesList(this.props.offering.categories),
    },
    {
      formType: 'input',
      name: 'item',
      icon: 'tag',
      label: 'Item',
      validations: {
        isExisty: true,
        minLength: 3,
      },
      validationErrors: {
        minLength: 'Please provide a slightly longer title for your item',
        isExisty: 'Please provide a title for your item',
        isDefaultRequiredValue: 'Please provide a title for your item',
      },
      instantValidation: false,
    },
    {
      formType: 'input',
      icon: 'browser',
      name: 'description',
      label: 'Item Description',
      required: false,
      validations: {
        minLength: 3,
        isExisty: true,
      },
      validationErrors: {
        minLength: 'Please provide a slightly longer description',
        isExisty: 'Please provide a description',
        isDefaultRequiredValue: 'Please provide a description',
      },
      instantValidation: false,
    },
    {
      formType: 'input',
      icon: 'dollar',
      name: 'price',
      label: 'Price',
      type: 'number',
      validations: {
        isExisty: true,
      },
      validationErrors: {
        isExisty: 'Please provide a price',
        isDefaultRequiredValue: 'Please provide a price',
      },
      instantValidation: false,
    },
  ];

  saveItemProperty = prop => {
    this.setState(state => ({ item: { ...state.item, ...prop } }));
  };

  saveItem = () => {
    this.props.addItemToOffering(this.state.item);
    this.setState({ item: {}, isValid: false }, () => {
      if (this.form) {
        setTimeout(() => this.form.reset(), 200);
      }
    });
  };

  handleDescriptionToggle = (e, { checked }) => {
    this.setState({ isShowingDescription: checked });
    if (!checked && this.props.offering && this.props.offering.description) {
      this.props.storeOfferingProperty({ description: null });
    }
  };

  handleEditItem = item => {
    const { removeItemFromOffering } = this.props;
    removeItemFromOffering(item);
    this.saveItemProperty(item);
  };

  render() {
    const { children, offering, storeOfferingProperty, removeItemFromOffering } = this.props;
    const { item, isValid, isShowingDescription } = this.state;
    const { items, description } = offering;
    console.log(' - item', item);
    return (
      <Grid
        columns={items && items.length ? 2 : 1}
        widths={items && item.length && 'equal'}
        container
        stackable
      >
        <Grid.Row>
          <Grid.Column>
            <Form
              onValid={() => this.setState({ isValid: true })}
              onInvalid={() => this.setState({ isValid: false })}
              onValidSubmit={this.saveItem}
              ref={node => (this.form = node)} // eslint-disable-line no-return-assign
            >
              <Checkbox
                className="small-margin margin-10"
                name="check-description"
                toggle
                label="Add an event description"
                checked={isShowingDescription}
                onChange={this.handleDescriptionToggle}
              />
              <Divider key="div" fitted />
              <Transition visible={isShowingDescription || !!description} animation="slide down">
                <Form.TextArea
                  {...DEFAULT_INPUT_PROPS}
                  placeholder="Provide an overall description for your event here.."
                  name="description"
                  label="Event Description"
                  value={description}
                  required={false}
                  onBlur={({ target }) => {
                    if (target.name) {
                      storeOfferingProperty({ [target.name]: target.value });
                    }
                  }}
                />
              </Transition>
              {this.formItems.map(itm => {
                const Field = this.forms[itm.formType];
                const props = omit(itm, 'formType');
                const defaults =
                  itm.formType === 'dropdown' || itm.formType === 'text'
                    ? omit(DEFAULT_INPUT_PROPS, 'transparent', 'iconPosition')
                    : DEFAULT_INPUT_PROPS;
                return [
                  <Field
                    {...defaults}
                    {...props}
                    key={itm.name}
                    value={item[itm.name]}
                    onBlur={({ target }) => {
                      if (target.name) {
                        this.saveItemProperty({ [target.name]: target.value });
                      }
                    }} // eslint-disable-line max-len
                    errorLabel={<Label color="red" pointing basic />}
                  />,
                  <Divider key="div" fitted />,
                ];
              })}
              <Form.Button
                disabled={!isValid}
                icon="plus"
                fluid
                color="blue"
                style={{ marginTop: 5 }}
              />
            </Form>
          </Grid.Column>
          {items && items.length > 0 && (
            <Grid.Column>
              <OfferingItemsList
                items={items}
                onEdit={this.handleEditItem}
                onRemove={removeItemFromOffering}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Divider fitted />
          {children({ isValid: (items && items.length) || (isShowingDescription && description) })}
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(
  toProps,
  {
    storeOfferingProperty,
    addItemToOffering,
    removeItemFromOffering,
  },
)(OfferingFormItems);
