import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { startCase } from 'lodash-es';
import { Menu, Responsive, Dropdown, Icon, Image } from 'semantic-ui-react';
import { logout } from '../../../redux/actions/global';
import { goToLogin } from '../../../enums/auth';

const bannerImage = require('../../../images/corkbord_banner.png');

/* eslint-disable react/prop-types */
class DashboardMenuBar extends PureComponent {
  state = {
    inverted: true,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const y = window.pageYOffset;
    if (y >= 1 && this.state.inverted) {
      this.setState({ inverted: false });
    } else if (y < 1 && !this.state.inverted) {
      this.setState({ inverted: true });
    }
  };

  handleItemClick = (e, { to }) => {
    const { history } = this.props;
    history.push(to);
  };

  handleLogout = () => {
    const { logout } = this.props;
    logout();
    goToLogin();
  };

  routes = [
    { name: 'dashboard', to: '/', icon: 'dashboard' },
    { name: 'account', to: '/account', icon: 'user' },
    { name: 'create offering', to: '/account/create', icon: 'ticket' },
  ];

  renderMenuItem = item => (
    <Menu.Item
      {...item}
      key={item.name}
      active={
        (this.props.location.pathname.search(item.to) === 0 && item.to.split('/').length >= 3) ||
        this.props.location.pathname === item.to
      }
      onClick={this.handleItemClick}
    />
  );

  renderDropdownItem = item => (
    <Dropdown.Item
      {...item}
      key={item.name}
      selected={
        (this.props.location.pathname.search(item.to) === 0 && item.to.split('/').length >= 3) ||
        this.props.location.pathname === item.to
      }
      text={startCase(item.name)}
      onClick={this.handleItemClick}
    />
  );

  renderMobileMenu = () => (
    <Dropdown
      floating
      icon={null}
      style={{ position: 'absolute', top: 20, left: 20 }}
      trigger={
        <Icon
          name="bars"
          inverted
          size="large"
          style={{ position: 'absolute', top: 20, left: 20 }}
        />
      }
    >
      <Dropdown.Menu>
        {this.routes.map(this.renderDropdownItem)}
        <Dropdown.Item text="logout" to="/" icon="sign out" onClick={this.handleLogout} />
      </Dropdown.Menu>
    </Dropdown>
  );

  render() {
    const { inverted } = this.state;
    return (
      <React.Fragment>
        <Responsive {...Responsive.onlyComputer}>
          <Menu inverted={inverted} pointing secondary={inverted} fixed="top" stackable>
            <Image src={bannerImage} spaced />
            <Menu.Menu position="right">
              {this.routes.map(this.renderMenuItem)}
              <Menu.Item name="logout" to="/" icon="sign out" onClick={this.handleLogout} />
            </Menu.Menu>
          </Menu>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>{this.renderMobileMenu()}</Responsive>
        <Responsive {...Responsive.onlyMobile}>{this.renderMobileMenu()}</Responsive>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    () => ({}),
    { logout },
  )(DashboardMenuBar),
);
