import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { clearToast } from '../../../redux/actions/global';

const toProps = state => ({
  ...state.toaster,
});

class Toaster extends PureComponent {
  componentWillMount() {
    const { message, configs } = this.props;
    this.dispatchToast(message, configs);
  }

  componentWillReceiveProps(nextProps) {
    const { message, configs } = nextProps;
    this.dispatchToast(message, configs);
  }

  dispatchToast = (message, configs) => {
    if (message) {
      toast(message, configs);
      this.props.clearToast();
    }
  };

  render() {
    return <ToastContainer {...this.props} />;
  }
}

export default connect(
  toProps,
  { clearToast },
)(Toaster);
