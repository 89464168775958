import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Header, Divider } from 'semantic-ui-react';

const toState = ({ account }) => ({
  userEditing: !!account.newAccountWizard.userEditing,
});

const ContactInfoFormHeader = ({ history, userEditing }) =>
  (userEditing ? (
    <Header content="Update any details" />
  ) : (
    <React.Fragment>
      <Button
        fluid
        onClick={() => history.push('/account/setup')}
        icon="search"
        color="blue"
        basic
        content="Find existing organization"
      />{' '}
      <Divider content="OR" horizontal section />
    </React.Fragment>
  ));

export default withRouter(connect(toState)(ContactInfoFormHeader));
