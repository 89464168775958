import { fromJS, Map } from 'immutable';
import { ADD_MARKER_GROUP, REMOVE_MARKER_GROUP } from '../actions/mapStyle';

export default (state = Map(), action) => {
  switch (action.type) {
    case ADD_MARKER_GROUP: {
      return state.set(action.payload.name, fromJS(action.payload.markers));
    }
    case REMOVE_MARKER_GROUP: {
      return state.delete(action.payload);
    }
    default:
      return state;
  }
};
