import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Transition } from 'semantic-ui-react';
import FormWrapper from '../../core/components/FormWrapper';
import ContactInfoForm from '../containers/ContactInfoForm';
import ContactInfoFormHeader from '../containers/ContactInfoFormHeader';

export default connect(({ account }) => ({ hasBusinessSelected: !isEmpty(account.business) }))(
  ({ hasBusinessSelected, ...props }) => {
    const [showInfoForm, toggleShowInfo] = useState(false);
    return (
      <FormWrapper>
        <ContactInfoFormHeader />
        <Transition
          visible={showInfoForm || hasBusinessSelected}
          animation="pulse"
          mountOnShow
          unmountOnHide
          transitionOnMount
        >
          <ContactInfoForm {...props} />
        </Transition>
        {!(showInfoForm || hasBusinessSelected) && (
          <Button
            fluid
            icon="vcard"
            color="blue"
            onClick={() => toggleShowInfo(true)}
            content="Start from scratch"
          />
        )}
      </FormWrapper>
    );
  },
);
