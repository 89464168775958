import React from 'react';

import { startCase, memoize, map } from 'lodash-es';
import { Label } from 'semantic-ui-react';

const toOptions = memoize(value => ({ text: startCase(value), value }));

const { NODE_ENV, REACT_APP_ENV } = process.env;

const isDev = NODE_ENV === 'development' || REACT_APP_ENV === 'development';

export const DEFAULT_TIMEOUT = isDev ? 10000 : 5000;
export const DEFAULT_DEBOUNCE = 350;
export const TIME_FORMAT = 'LT';
export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
export const RECUR_ITEMS = ['day', 'week', 'month', 'year'];

export const DAYS_OF_WEEK_OPTIONS = DAYS_OF_WEEK.map(toOptions);
export const RECUR_OPTIONS = RECUR_ITEMS.map(toOptions);

export const DEFAULT_INPUT_PROPS = {
  required: true,
  transparent: true,
  className: 'small-margin',
  iconPosition: 'left',
  errorLabel: <Label color="red" pointing basic />,
};

export const FUN_EVENT_FACTS = [
  '41% marketers believe that events are the single-most effective marketing channel \n(Bizzabo, 2018)',
  'Between 2017 and 2018 the number of companies organizing 20 or more events per year increased by 17%. \n(Bizzabo, 2018)',
  '84% of leadership  believe in-person events are a critical component of their company’s success. \n(Bizzabo, 2018)',
  'The most successful businesses are spending 1.7x the average marketing budget on live events. \n(Bizzabo, 2018)',
  '223 hours per year is the average time saved by businesses using event software. \n(Bizzabo, 2018)',
  '46% of leadership (Vice President and C-Suite) agree that in-person events are the most critical marketing channel to achieve key business objectives. \n(Bizzabo, 2018)',
  '22% of businesses within commercial & professional services industry allocate over 51% of their marketing budgets to in-person events while most (46%) spend 1-20%. \n(Bizzabo, 2018)',
  'Overperforming businesses are 2x more likely to use event software. \n(Bizzabo, 2018)',
  '57% of event organizers use event management software. \n(Bizzabo, 2018)',
  'Businesses save 18.6 hours a month on event planning by using event software. \n(Bizzabo, 2018)',
  'Businesses that do use event software are 24% more likely to prove event ROI.  \n(Bizzabo, 2018)',
  '62% of marketers who use email marketing to promote their events use event management software. \n(Bizzabo, 2017)',
  '$50 million was invested in modern event management solutions in 2016. \n(Bizzabo, 2017)',
];

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const statesDropdownOptions = map(STATES, (value, key) => ({ text: value, key, value }));

export * from './env';
