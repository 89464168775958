import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Transition, List, ButtonGroup, Button } from 'semantic-ui-react';

class OfferingItem extends PureComponent {
  state = { hover: false };

  render() {
    const { type, item, description, price, onEdit, onRemove } = this.props;
    const { hover } = this.state;
    return (
      <List.Item
        onMouseEnter={() => this.setState({ hover: !hover })}
        onMouseLeave={() => this.setState({ hover: !hover })}
      >
        <List.Icon name="check circle" verticalAlign="middle" size="large" />
        <List.Content>
          <List.Header content={`${item} ($${price})`} />
          <List.Content>
            {description}
            {hover && (onEdit || onRemove) && (
              <ButtonGroup floated="right">
                {onEdit && (
                  <Button
                    icon="pencil"
                    color="blue"
                    basic
                    onClick={() => onEdit({ type, item, description, price })}
                  />
                )}
                {onRemove && (
                  <Button
                    icon="delete"
                    color="red"
                    basic
                    onClick={() => onRemove({ item, price })}
                  />
                )}
              </ButtonGroup>
            )}
          </List.Content>
          <List.Description content={type} />
        </List.Content>
      </List.Item>
    );
  }
}

const OfferingItemsList = ({ items, onEdit, onRemove, ...props }) => (
  <Transition.Group
    visible={items && items.length}
    animation="fade"
    as={List}
    divided
    style={{ maxHeight: '150px', overflow: 'auto' }}
    {...props}
  >
    {items.map(offering => (
      <OfferingItem
        key={offering.item + offering.price}
        onEdit={onEdit}
        onRemove={onRemove}
        {...offering}
      />
    ))}
  </Transition.Group>
);

OfferingItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

OfferingItemsList.defaultProps = {
  items: [],
};

export default OfferingItemsList;
