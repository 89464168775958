import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Confirm, Header } from 'semantic-ui-react';
import OfferingSummaryCard from '../../core/components/OfferingSummaryCard';
import { sendOfferingToStore, removeOfferingFromBusiness } from '../../../redux/reducers/offering';
import FormWrapper from '../../core/components/FormWrapper';

const toProps = ({ account }) => ({
  offerings: account.business.offerings,
  hasBusinessSelected: !!account.business.name,
});

const cardStyle = {
  overflowY: 'hidden',
  overflowWrap: 'break-word',
  overflowX: 'hidden',
  padding: 0,
  margin: 5,
};

/* eslint-disable max-len */
class DashboardOfferingList extends PureComponent {
  state = {
    removeId: null,
  };

  handleOfferingClick = offering => {
    const { sendOfferingToStore, history } = this.props;
    sendOfferingToStore(offering);
    history.push('/account/create');
  };

  handleDeleteOffering = offeringId => {
    this.props.removeOfferingFromBusiness(offeringId);
    this.setState({ removeId: null });
  };

  render() {
    const { offerings = [], hasBusinessSelected, history } = this.props;
    const { removeId } = this.state;
    return (
      <FormWrapper>
        <Header content="Offerings" />
        <Confirm
          open={!!removeId}
          onConfirm={() => this.handleDeleteOffering(removeId)}
          onCancel={() => this.setState({ removeId: false })}
          content="Are you sure you want to remove this offering?"
        />
        <Card.Group centered stackable doubling>
          {offerings.length ? (
            <React.Fragment>
              <Card raised fluid style={cardStyle}>
                <Card.Content extra>
                  <Button
                    fluid
                    color="blue"
                    content="Create New"
                    onClick={() => history.push('/account/create')}
                  />
                </Card.Content>
              </Card>
              {offerings.map(offering => (
                <OfferingSummaryCard
                  onClick={() => this.handleOfferingClick(offering)}
                  key={offering.title || offering.timeStart}
                  style={cardStyle}
                  offering={offering}
                >
                  <div style={{ float: 'left', position: 'absolute' }}>
                    <Button
                      icon="remove"
                      circular
                      color="red"
                      title="delete"
                      onClick={() => this.setState({ removeId: offering.id })}
                    />
                    <Button
                      icon="edit"
                      circular
                      color="blue"
                      title="edit"
                      onClick={() => this.handleOfferingClick(offering)}
                    />
                  </div>
                </OfferingSummaryCard>
              ))}
            </React.Fragment>
          ) : (
            <Card
              fluid
              meta={
                hasBusinessSelected
                  ? "No Offerings? No problem! Let's make a few.."
                  : 'Select a business to view events'
              }
              description={
                hasBusinessSelected && (
                  <Button
                    style={{ marginTop: 8 }}
                    fluid
                    color="blue"
                    content="Start Here!"
                    onClick={() => history.push('/account/create')}
                  />
                )
              }
            />
          )}
        </Card.Group>
      </FormWrapper>
    );
  }
}

export default connect(
  toProps,
  { sendOfferingToStore, removeOfferingFromBusiness },
)(DashboardOfferingList);
