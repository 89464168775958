import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import OfferingSummaryCard from '../components/OfferingSummaryCard';
import { setOfferingInBusiness } from '../../../redux/reducers/offering';

const toProps = ({ offering }) => ({
  offering,
});

class OfferingSummary extends PureComponent {
  render() {
    const { offering, history, storeOfferingToBusiness } = this.props;
    return isEmpty(offering) ? (
      <Redirect to="/dashboard/add-event/who" />
    ) : (
      <OfferingSummaryCard offering={offering}>
        <Button.Group fluid size="small" inverted>
          <Button
            color="orange"
            icon="arrow left"
            onClick={() => history.goBack()}
            content="Rework"
          />
          <Button.Or text="or" />
          <Button
            color="blue"
            icon="check"
            onClick={() => storeOfferingToBusiness(offering)}
            content="Accept"
          />
        </Button.Group>
      </OfferingSummaryCard>
    );
  }
}

export default connect(
  toProps,
  {
    storeOfferingToBusiness: setOfferingInBusiness,
  },
)(OfferingSummary);
