import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Grid, Confirm, Segment, Header, Transition, Button, Divider } from 'semantic-ui-react';
import BusinessSearch from './BusinessSearch';
import BusinessCard from '../../core/components/BusinessCard';
import { setBusiness } from '../../../redux/reducers/business';
import { clearNewAccountSelectedBusiness, toggleUserEditingNewBusiness, changeAutoCompleteBusinessValue } from '../../../redux/reducers/new-account-wizard';

const toProps = ({ account }) => ({
  ...account.newAccountWizard,
  business: account.business,
});

class BusinessAdoptionComponent extends PureComponent {
  state = {
    confirm: false,
  };

  handleConfirmBusiness = () => {
    const {
      history,
      selectedBusiness,
      clearNewAccountSelectedBusiness,
      setBusiness,
      toggleUserEditingNewBusiness,
      changeAutoCompleteBusinessValue,
    } = this.props;
    this.setState({ confirm: false }, async () => {
      toggleUserEditingNewBusiness(true);
      setBusiness(selectedBusiness);
      history.push('/account');
      clearNewAccountSelectedBusiness();
      changeAutoCompleteBusinessValue('');
    });
  };

  render() {
    const { selectedBusiness, clearNewAccountSelectedBusiness, history } = this.props;
    const { confirm } = this.state;
    const { name } = selectedBusiness || {};
    return (
      <React.Fragment>
        <Confirm
          open={confirm}
          size="tiny"
          header={`Your organization is ${name}?`}
          content="And you'd like to keep everyone up to date on what's going on?"
          cancelButton={{ size: 'tiny', color: 'red', basic: true, content: 'Nope' }}
          confirmButton={{ size: 'tiny', color: 'blue', basic: true, content: 'Yes, I would!' }}
          onCancel={() => this.setState({ confirm: false }, clearNewAccountSelectedBusiness)}
          onConfirm={this.handleConfirmBusiness}
        />
        <Grid container columns={1}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment raised color="blue">
                <Header
                  icon="search"
                  content="Welcome to Corkbord!"
                  subheader="We might already have some information on your organization, take a look!"
                />
                <BusinessSearch
                  inputProps={{ fluid: true, transparent: true }}
                  searchProps={{ className: 'input-white-font' }}
                />
                <Divider horizontal content="OR" style={{ marginTop: 2 }} />
                <Button
                  style={{ marginTop: 10 }}
                  fluid
                  size="small"
                  basic
                  color="blue"
                  content="Start from scratch"
                  onClick={() => {
                    history.push('/account');
                  }}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Transition
                visible={!!name}
                animation="slide down"
                unmountOnHide
                mountOnShow
                transitionOnMount
              >
                <Segment color="blue" attached>
                  <Header content={`Is ${name} yours?`} />
                  {selectedBusiness && (
                    <BusinessCard business={selectedBusiness} isPublic={false} />
                  )}
                  <Button.Group fluid>
                    <Button
                      color="red"
                      basic
                      content="Clear"
                      onClick={clearNewAccountSelectedBusiness}
                    />
                    <Button
                      color="blue"
                      basic
                      content="Yep!"
                      onClick={() => this.setState({ confirm: true })}
                    />
                  </Button.Group>
                </Segment>
              </Transition>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default connect(
  toProps,
  {
    setBusiness,
    clearNewAccountSelectedBusiness,
    toggleUserEditingNewBusiness,
    changeAutoCompleteBusinessValue,
  },
)(BusinessAdoptionComponent);
