import { MAP_VIEWPORT_CHANGE } from '../actions/viewport';

const initialState = {
  latitude: 38.89643965967894,
  longitude: -77.0093392546974,
  zoom: 13,
  bearing: 0,
  pitch: 0,
  width: 500,
  height: 500,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MAP_VIEWPORT_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
