import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import AccountForm from '../containers/AccountForm';
import OwnersBusinessList from '../../core/containers/OwnersBusinessList';

const AccountHome = () => (
  <Container>
    <Grid columns={2} divided="vertically" padded doubling>
      <Grid.Column width={6}>
        <OwnersBusinessList />
      </Grid.Column>
      <Grid.Column width={10}>
        <AccountForm />
      </Grid.Column>
    </Grid>
  </Container>
);

export default AccountHome;
