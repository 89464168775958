import { get } from 'lodash-es';

export const CHANGE_TOAST = 'global/CHANGE_TOAST';
export const changeToast = (message, configs) => ({
  type: CHANGE_TOAST,
  payload: { message, configs },
});

export const CLEAR_TOAST = 'global/CLEAR_TOAST';
export const clearToast = payload => ({
  type: CLEAR_TOAST,
  payload,
});

export const LOG_ERROR = 'global/LOG_ERROR';
const errorHandler = dispatch => (err, { toast = true }) => {
  if (process.env.NODE_ENV === 'development') console.error(err); // eslint-disable-line no-console
  const { response = {} } = err || {};
  const payload = get(response, 'data.message') || err.toString();
  if (dispatch) {
    dispatch({ type: LOG_ERROR, payload });
    if (toast) {
      dispatch(changeToast(payload, { type: 'error' }));
    }
  }
  return [{ type: LOG_ERROR, payload }, toast && changeToast(payload, { type: 'error' })].filter(
    Boolean,
  );
};
export const handleErrorThunk = (...args) => dispatch => errorHandler(dispatch)(...args);
export const handleError = errorHandler();

export const LOGOUT = 'global/LOGOUT';
export const logout = () => ({ type: LOGOUT });
