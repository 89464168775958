import React from 'react';
import { connect } from 'react-redux';
import { Header, HeaderSubheader } from 'semantic-ui-react';
import FormWrapper from '../components/FormWrapper';
import BusinessCard from '../components/BusinessCard';
import { setBusiness } from '../../../redux/reducers/business';

const toState = ({ account }) => ({
  businesses: account.businesses,
  selectedId: account.business && account.business.oid,
});

const OwnersBusinessList = ({ businesses, setBusiness, selectedId }) => (
  <FormWrapper>
    <Header content="Your organizations" />
    {businesses.length ? (
      businesses.map(b => (
        <BusinessCard
          key={b.oid}
          business={b}
          isSelected={selectedId === b.oid}
          onClick={() => setBusiness(b)}
        />
      ))
    ) : (
      <HeaderSubheader>
        You have no businesses currently, feel free to create or find one in the accounts page
      </HeaderSubheader>
    )}
  </FormWrapper>
);

export default connect(
  toState,
  { setBusiness },
)(OwnersBusinessList);
