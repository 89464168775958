import React from 'react';
// import PropTypes from 'prop-types';
import { memoize, flow, compact, startCase } from 'lodash-es';
import { map } from 'lodash/fp';
import { Card, Icon, Image, Label } from 'semantic-ui-react';
import { createAddressString } from '../../../utils';

const renderContactLabels = memoize(
  flow(
    compact,
    map(i => <Label key={i} content={i} />),
  ),
);

/* eslint-disable max-len */
export default ({
  business,
  onIconClick,
  onClick,
  iconName = 'settings',
  isPublic,
  isSelected,
}) => {
  const {
    name,
    addressString,
    publicPhone,
    twitter,
    facebook,
    _contact,
    _contactEmail,
    tags = [],
  } = business;
  return (
    <Card fluid centered raised={isSelected} onClick={onClick}>
      <Card.Content>
        {onIconClick && (
          <Image floated="right">
            <Icon
              name={iconName}
              size="large"
              link
              onClick={onIconClick}
              title="click to edit profile"
            />
          </Image>
        )}
        <Card.Header content={startCase(name)} />
        <Card.Meta
          content={addressString || createAddressString(business) || 'No address provided'}
        />
        <Card.Description content={renderContactLabels([twitter, facebook])} />
      </Card.Content>
      {!!publicPhone && <Card.Content extra>{publicPhone}</Card.Content>}
      {!isPublic && !!_contactEmail && (
        <Card.Content extra>
          {_contact} ({_contactEmail})
        </Card.Content>
      )}
      {tags.length > 0 && (
        <Card.Content extra>
          {tags.map(tag => (
            <Label key={tag} content={tag} basic color="blue" />
          ))}
        </Card.Content>
      )}
    </Card>
  );
};
