import axios from 'axios';
import qs from 'querystring';

const { REACT_APP_CORKBORD_API_URI } = process.env;

export default axios.create({
  baseURL: REACT_APP_CORKBORD_API_URI,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params),
  timeout: 10000,
});
