import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { omit, isEmpty } from 'lodash-es';
import { Form } from 'formsy-semantic-ui-react';
import { Grid, Label, Divider, Header, Button, Confirm } from 'semantic-ui-react';
import {
  storeContactProperty,
  postBusinessInfo,
  removeOwnerFromBusiness,
  clearBusiness,
} from '../../../redux/reducers/business';
import { toggleUserEditingNewBusiness } from '../../../redux/reducers/new-account-wizard';
import { postAttributesToUserProfile } from '../../../redux/reducers/user';
import { urlRegExp } from '../../core/containers/offering-forms/enums';
import { DEFAULT_INPUT_PROPS, statesDropdownOptions } from '../../../enums';

const toProps = ({ account, user }) => ({
  business: account.business,
  username: user.username,
  isOwner: user.isOwner,
  userEditing: account.newAccountWizard.userEditing,
});

class NewEstablishment extends PureComponent {
  state = {
    isValid: false,
    confirmDelete: false,
  };

  forms = {
    input: Form.Input,
    text: Form.TextArea,
    dropdown: Form.Dropdown,
  };

  contactForms = [
    {
      formType: 'input',
      name: 'publicPhone',
      icon: 'phone',
      label: 'Public Phone',
      placeholder: '###-###-####',
      required: false,
      validations: {
        minLength: 10,
      },
      validationErrors: {
        minLength: 'Please provide complete phone Number ###-###-####',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'input',
      name: 'url',
      icon: 'rss',
      label: 'Website',
      placeholder: 'https://www.coolorg.com',
      required: false,
      validations: { matchRegexp: urlRegExp },
      value: 'https://',
      validationErrors: { matchRegexp: 'Please provide a valid URL' },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'input',
      name: 'twitter',
      icon: 'twitter',
      label: 'Twitter',
      value: '@',
      placeholder: '@twitter',
      required: false,
      validations: { minLength: 2, matchRegexp: /@[a-zA-Z0-9]*/ },
      validationErrors: {
        minLength: 'Please provide a valid twitter',
        matchRegexp: 'Please include @ in the beginning',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'input',
      name: 'facebook',
      icon: 'facebook',
      label: 'Facebook URL',
      placeholder: '/facebook',
      required: false,
      validations: { minLength: 3 },
      value: '',
      validationErrors: {
        minLength: 'Please provide a valid facebook name',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
  ];

  organizationForms = [
    {
      formType: 'input',
      name: 'name',
      icon: null,
      iconPosition: null,
      label: 'Name',
      placeholder: 'Awesome Business',
      validations: {
        minLength: 3,
      },
      validationErrors: {
        minLength: 'Please provide a longer name',
        isDefaultRequiredValue: 'Please provide a name for your establishment',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'input',
      name: 'address',
      icon: null,
      iconPosition: null,
      label: 'Street Address',
      placeholder: '123 Fake St. South',
      validations: {
        minLength: 6,
      },
      validationErrors: {
        minLength: 'Please provide a longer street address',
        isDefaultRequiredValue: 'Please provide a street address for your establishment',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'input',
      name: 'city',
      icon: null,
      iconPosition: null,
      label: 'City',
      placeholder: 'Springfield',
      validationErrors: {
        isDefaultRequiredValue: 'Please provide a city for your establishment',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
    {
      formType: 'dropdown',
      name: 'state',
      icon: null,
      label: 'State',
      placeholder: 'Illinois',
      scrolling: true,
      fluid: true,
      search: true,
      validationErrors: {
        isDefaultRequiredValue: 'Please provide a state for your establishment',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
      onChange: (e, { name, value }) => this.props.storeContactProperty({ [name]: value }),
      options: statesDropdownOptions,
    },
    {
      formType: 'input',
      name: 'zipCode',
      icon: null,
      iconPosition: null,
      label: 'Zipcode',
      placeholder: '20002',
      required: true,
      validations: {
        minLength: 5,
      },
      validationErrors: {
        minLength: 'Please provide a longer zip',
        isDefaultRequiredValue: 'Please provide a zipcode for your establishment',
      },
      instantValidation: false,
      errorLabel: <Label color="red" pointing />,
    },
  ];

  handleGoBack = () => {
    const { toggleUserEditingNewBusiness, clearBusiness } = this.props;
    clearBusiness();
    toggleUserEditingNewBusiness(false);
  };

  renderInput = item => {
    const { storeContactProperty, business } = this.props;
    const Field = this.forms[item.formType];
    const props = omit(item, 'formType');
    const defaults =
      item.formType === 'dropdown'
        ? omit(DEFAULT_INPUT_PROPS, 'transparent', 'iconPosition')
        : DEFAULT_INPUT_PROPS;
    return [
      <Field
        {...defaults}
        {...props}
        key={item.name}
        value={business[item.name]}
        onBlur={({ target }) => {
          if (target.name) {
            storeContactProperty({ [target.name]: target.value });
          }
        }}
      />,
      <Divider style={{ margin: '2px 0px 5px' }} key="div" />,
    ];
  };

  render() {
    const {
      business,
      username,
      postBusinessInfo,
      clearBusiness,
      postAttributesToUserProfile,
      toggleUserEditingNewBusiness,
      isOwner,
      userEditing,
      removeOwnerFromBusiness,
    } = this.props;
    const { isValid, confirmDelete } = this.state;
    const noBusiness = isEmpty(business);
    return (
      <Grid container columns={2} widths="equal" stackable>
        <Confirm
          open={confirmDelete}
          onCancel={() => this.setState({ confirmDelete: false })}
          onConfirm={() => {
            this.setState({ confirmDelete: false });
            removeOwnerFromBusiness(business.oid);
            clearBusiness();
          }}
          header={`Remove ${business.name}`}
          content={`Are you sure you'd like to remove ${business.name} from your account?`}
          size="small"
        />
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" content="Organization" subheader="Tell us about your organization" />
            <Form>{this.organizationForms.map(this.renderInput)}</Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" content="Contact" subheader="Tell us how customers can find you" />
            <Form onValid={() => this.setState({ isValid: true })}>
              {this.contactForms.map(this.renderInput)}
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Divider horizontal clearing />
          <Grid container columns={4} widths="equal" stackable>
            <Grid.Column>
              <Button
                fluid
                color="blue"
                disabled={!isValid}
                onClick={async () => {
                  toggleUserEditingNewBusiness(false);
                  await postBusinessInfo({ ...business, _owner: username });
                  if (!isOwner) {
                    postAttributesToUserProfile({ isOwner: true });
                  }
                }}
                icon="save"
              />
            </Grid.Column>
            <Grid.Column>
              {!userEditing && (
                <Button
                  fluid
                  disabled={noBusiness}
                  color="red"
                  content="Remove"
                  icon="remove"
                  onClick={() => this.setState({ confirmDelete: true })}
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                disabled={noBusiness}
                color="orange"
                content="Go Back"
                icon="left arrow"
                onClick={this.handleGoBack}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                disabled={noBusiness}
                color="blue"
                content="Clear"
                icon="eraser"
                onClick={clearBusiness}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(
  toProps,
  {
    storeContactProperty,
    postBusinessInfo,
    postAttributesToUserProfile,
    removeOwnerFromBusiness,
    toggleUserEditingNewBusiness,
    clearBusiness,
  },
)(NewEstablishment);
