import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AccountHome from './AccountHome';
import OfferingsWizard from '../../core/containers/offering-forms/OfferingsWizard';
import BusinessAdoptionContainer from '../containers/BusinessAdoptionContainer';

const Account = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route exact path={`${match.url}/`} component={AccountHome} />
      <Route path={`${match.url}/setup`} component={BusinessAdoptionContainer} />
      <Route path={`${match.url}/create`} component={OfferingsWizard} />
    </Switch>
  </React.Fragment>
);

export default Account;
