import qs from 'qs';
import corkbordApi from './enums/services';
import { setTokenCookies, getCookies, getTokenMetadata, goToLogin, HEADERS } from './enums/auth';
// import User from './enums/user';
import { setUserMetaData } from './redux/reducers/user';
import { fetchOwnersBusiness } from './redux/reducers/businesses';

export const handleLoginTokens = () => {
  const queryString = qs.parse(window.location.hash.replace('#', ''));
  const accessToken = queryString.access_token || getCookies('accessToken');
  const idToken = queryString.id_token || getCookies('idToken');
  const refreshToken = queryString.refresh_token || getCookies('refreshToken');

  if (!idToken) {
    // token session expired, send user to log back in
    goToLogin();
  }

  if (queryString.id_token) {
    // if queryString has a token, then we should add/replace the new tokens as cookies
    setTokenCookies({ idToken, accessToken, refreshToken, expires: queryString.expires_in });
  }

  // update axios instance
  corkbordApi.defaults.headers[HEADERS.ACCESS_TOKEN] = accessToken;
  corkbordApi.defaults.headers.common[HEADERS.ACCESS_TOKEN] = accessToken;
  corkbordApi.defaults.headers[HEADERS.ID_TOKEN] = idToken;
  corkbordApi.defaults.headers.common[HEADERS.ID_TOKEN] = idToken;
  return { ...getTokenMetadata({ token: idToken }), idToken, accessToken, refreshToken };
};

export default dispatch => {
  const { email, accessToken, idToken, refreshToken, exp: expires, ...meta } = handleLoginTokens();
  const username = meta['cognito:username'];
  const isOwner = meta['custom:isOwner'] === 'true';
  // User.getCognitoUser(username);
  dispatch(fetchOwnersBusiness());
  // User.createRefreshTokenTimer(expires, () => User.checkTokenExpiration(refreshToken));
  dispatch(setUserMetaData({ email, username, isOwner /* accessToken, idToken, refreshToken */ }));
};
