import { LOGOUT } from '../actions/global';

export const SET_USER_META_DATA = 'user/SET_USER_META_DATA';
export const setUserMetaData = payload => ({
  type: SET_USER_META_DATA,
  payload,
});

export const POST_ATTRIBUTES_TO_USER_PROFILE = 'user/POST_ATTRIBUTES_TO_USER_PROFILE';
export const postAttributesToUserProfile =
  attributes => async (dispatch, getState, { api, changeToast, toggleLoader }) => {
    dispatch(toggleLoader(true));
    await api.post('/users/attributes', { attributes }); // todo: handle return
    dispatch(toggleLoader(false));
    dispatch(changeToast('Your profile was updated', { type: 'success' }));
    dispatch({
      type: POST_ATTRIBUTES_TO_USER_PROFILE,
      payload: { attributes },
    });
  }

const initialState = {};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_META_DATA:
      return {
        ...state,
        ...payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
