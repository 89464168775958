import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';
import { roundedTime } from '../../../utils';
import { DEFAULT_INPUT_PROPS } from '../../../enums';

const TimeInput = ({ meridiem, value: providedValue, onChange, ...props }) => {
  const value = providedValue || roundedTime();
  const [time, timeMeridiem] = value.split(' ');
  return (
    <Form.Input
      {...DEFAULT_INPUT_PROPS}
      type="text"
      fluid
      value={time}
      validations={{ isExisty: true, matchRegexp: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/ }}
      validationErrors={{ isExisty: 'Please provide an end time', matchRegexp: 'Time input must be hh:MM, select AM/PM to the right' }}
      action={{
        labelPosition: 'right',
        content: meridiem || timeMeridiem,
        onClick: () => onChange(`${time} ${timeMeridiem === 'PM' ? 'AM' : 'PM'}`),
      }}
      {...props}
      onChange={(e, { value }) => onChange(`${value} ${timeMeridiem === 'PM' ? 'AM' : 'PM'}`)}
    />
  );
};

TimeInput.propTypes = {
  meridiem: PropTypes.string,
  value: PropTypes.string,
};

export default TimeInput;
