import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Segment, Header, Icon } from 'semantic-ui-react';
import OwnersBusinessList from '../../core/containers/OwnersBusinessList';

const toProps = ({ account }) => ({
  hasBusinesses: account.businesses.length,
});

class BusinessCardContainer extends PureComponent {
  render() {
    const { hasBusinesses, history } = this.props;
    return hasBusinesses ? (
      <OwnersBusinessList />
    ) : (
      <Segment>
        <Header as="h3" textAlign="center" icon>
          <Icon link name="arrow circle right" onClick={() => history.push('/account/setup')} />
          Start Here
          <Header.Subheader>You have not created or adopted a business</Header.Subheader>
        </Header>
      </Segment>
    );
  }
}

export default connect(toProps)(withRouter(BusinessCardContainer));
